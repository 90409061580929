import DropDownMenu_SeparateIndentedMenuButtonSkinComponent from '../components/DropDownMenu/viewer/skinComps/SeparateIndentedMenuButtonSkin/SeparateIndentedMenuButtonSkin.skin';
import DropDownMenu_SeparateIndentedMenuButtonSkinController from '../components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_SeparateIndentedMenuButtonSkin = {
  component: DropDownMenu_SeparateIndentedMenuButtonSkinComponent,
  controller: DropDownMenu_SeparateIndentedMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_SeparateIndentedMenuButtonSkin']: DropDownMenu_SeparateIndentedMenuButtonSkin
};

